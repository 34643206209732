// to add! TMC colors.

export const colors = {
  white: "#fff",
  black: "#000",
  gray: "#818181",
  primary: "#FFBF46",
  secondary: "#59FFA0",
  primaryTMC: "#211d49", // darkpurple
  secondaryTMC: "#565182", // faded purple
  TMCWhite: "#fff",
  TMCGrey: "#252525",
  TMCDarkGrey: "var(--main-color)",
  TMCGoldYellow: "#ffc549",
}
