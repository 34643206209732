import React from "react"
import styled from "styled-components"

const Button = ({ children }) => {
  return <CustomButton /* onClick={props} */>{children}</CustomButton>
}

const CustomButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 25%;
  max-width: 33%;
  padding: 14px 14px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: whitesmoke;
  // background-color: orange;
  background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);

  border: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  text-transform: uppercase;
  border-radius: 15px;
  text-align: center;

  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: scale(1.04);
    background-color: #353535;
  }

  a {
    text-decoration: none;
    color: white;
  }
  @media only screen and (max-width: 768px) {
    max-width: 50%;
  }
`

export default Button
