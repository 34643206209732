import styled, { css } from "styled-components"
import { colors } from "./global.js"

export const Container = styled.div`
  width: 1280px;
  min-width: 1280px;
  margin: 0 auto;
  .row {
    width: 100%;
    display: flex;
    align-items: center;
  }
`

export const FrontPageTitle = styled.h1`
  margin: 0 auto;
  justify-content: center;
  color: #ffc549;
  padding-left: 25px;
  padding-top: 50px;
  padding-bottom: 20px;
  max-width: 80%;
  font-size: 2rem;
  text-transform: uppercase;
  @media (min-width: 200px) {
    min-width: 95%;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    min-width: 95%;
  }
`
export const Paragraph = styled.div`
  padding: 1.5% 10%;
  text-align: left;
  margin: 0 auto;
  width: 60%;
  color: var(--text-gray);
  @media (min-width: 200px) and (max-width: 768px) {
    width: 90%;
    max-width: 93%;
  }
`

export const LinearH1 = styled.h1`
  margin: 0 auto;
  margin-bottom: 1.45rem;
  background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-content: center;
  word-wrap: break-word;
  //text-shadow: 2px 2px 5px goldenrod;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 80%;
  font-size: 2.8rem;
  text-transform: uppercase;
  @media (min-width: 200px) {
    min-width: 95%;
    font-size: 2.8rem;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    min-width: 95%;
    font-size: 1.8rem;
  }
`

export const LinearTitle = styled.h2`
  margin: 0 auto;
  //background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  //background-clip: text;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  justify-content: center;
  word-wrap: break-word;
  //text-shadow: 2px 2px 5px goldenrod;
  color: white;
  font-family: var(--jost-font);
  padding-top: 35px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 80%;
  font-size: 2rem;
  text-transform: uppercase;
  @media (min-width: 200px) {
    min-width: 95%;
    font-size: 1.5rem;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    min-width: 95%;
    font-size: 1.5rem;
  }
`

export const Subhead = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin: 160px 0 48px;
`

export const Buttons = styled.a`
  height: 48px;
  line-height: 48px;
  padding: 0 64px;
  border-radius: 100px;
  color: ${colors.black};
  background: ${colors.white};
  cursor: pointer;
  margin: 0 24px;
  font-size: 14px;
  text-decoration: none;

  ${props =>
    props.outline &&
    css`
      background: transparent;
      border: 1px solid ${colors.black};
    `}

  ${props =>
    props.primary &&
    css`
      background: ${colors.primary};
    `}

    ${props =>
    props.secondary &&
    css`
      background: ${colors.secondary};
    `}
`
export const SearchBar = styled.div`
  height: 48px;
  width: 700px;
  position: relative;
  margin: 0 24px;

  input {
    height: 48px;
    width: 700px;
    padding: 0 64px;
    border-radius: 100px;
    border: none;
    font-size: 14px;
    outline: none;
    color: ${colors.black};
    &::placeholder {
      color: ${colors.grey};
    }
  }

  img {
    position: absolute;
    top: 30%;
    left: 24px;
    transform: translateY() (-50%);
    z-index: 10;
    width: 16px;
    height: 16px;
  }
`

// Flex Wrapper

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 40%;
  @media (min-width: 200px) and (max-width: 768px) {
    width: 90%;
  }
`
// Video element

// this one is for index page?

export const StyledFrame = styled.iframe`
  display: block;
  //margin: 0px 25px 0px 25px;
  width: 90%;
  max-height: 65%;
  justify-content: center;
  @media (min-width: 200px) and (max-width: 768px) {
    padding-left: 0px;
    margin: 0 auto;
    margin-top: 15px;
    min-width: 90%;
    max-width: 90%;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-left: 0px;
    margin: 0 auto;
    margin-top: 15px;
    min-width: 90%;
    max-width: 90%;
  }
`

// this one is for article page?

export const StyledFrame2 = styled.iframe`
  display: block;
  margin: 15px;
  width: 100%;
  min-height: 50vh;
  max-height: 100%;
  justify-content: center;
  @media (min-width: 200px) and (max-width: 768px) {
    padding-left: 0px;
    margin-left: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 30vh;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    padding-left: 0px;
    margin-left: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 30vh;
  }
`

export const HeaderGrid = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  min-height: auto;
  //background: yellow;
  max-width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const ImgWrap = styled.div`
  position: relative;
  //padding: 145px;
  padding: 15px;

  border-radius: 15px;
  width: 50%;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`

export const FrameWrap = styled.div`
  position: static;
  width: ${props => (props.medium ? "40%" : "70%")};
  border: ${props => (props.border ? "2px solid darkorange" : "none")};
  display: flex;
  flex-direction: column;
  padding: 15px;
  //min-height: 50vh;
  width: 50%;
  //border: 1px dashed hotpink;
  @media screen and (min-width: 200px) and (max-width: 768px) {
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
`
export const FrameText = styled.div`
  display: flex;
  width: 70%;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding: 30px;
  background: var(--main-color);
  border-radius: 25px;
  h1 {
    //font-size: 1.8rem;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  @media (min-width: 200px) and (max-width: 768px) {
    padding: 10px;
    min-width: 90%;
    max-width: 90%;
    margin-bottom: 15px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 10px;
    min-width: 90%;
    max-width: 90%;
    margin-bottom: 15px;
  }
`

export const PageBlock = styled.div`
  height: 1px; // how to enter a prop??
  // ${props => (props.primary ? "white" : "palevioletred")};
  // color: ${props => props.inputColor || "palevioletred"};
`

/* Example for passing in attached props

const Input = styled.input.attrs(props => ({
  // we can define static props
  type: "text",

  // or we can define dynamic ones
  size: props.size || "1em",
}))`
  color: palevioletred;
  font-size: 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;

  here we use the dynamically computed prop 
  margin: ${props => props.size};
  padding: ${props => props.size};
`;

render(
  <div>
    <Input placeholder="A small text input" />
    <br />
    <Input placeholder="A bigger text input" size="2em" />
  </div>
);

*/
