import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  List,
  /*CourseImg, */
  ImgCont,
  CourseBlock,
  CourseTitle,
  ReadMore,
  ContentWrapper,
  CCategory,
} from "./CourseStyles.js"

export const CourseList = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCourse(limit: 3) {
        edges {
          node {
            id
            author
            category
            courseImage {
              gatsbyImageData(width: 400)
              title
            }
            subtitle
            title
            slug
          }
        }
      }
    }
  `)

  // This one works statically, how to do dynamically?
  //console.log("image2", image2)

  return (
    <div>
      <List>
        {data.allContentfulCourse.edges.map(edge => {
          const image = getImage(edge.node.courseImage.gatsbyImageData)
          return (
            <CourseBlock key={edge.node.id}>
              <ImgCont>
                {edge.node.courseImage && (
                  <GatsbyImage image={image} alt={""} />
                )}
              </ImgCont>
              <ContentWrapper>
                <CCategory>{edge.node.category}</CCategory>
                <CourseTitle>{edge.node.title}</CourseTitle>

                <ReadMore to={`/courses/${edge.node.slug}`}>READ MORE</ReadMore>
              </ContentWrapper>
            </CourseBlock>
          )
        })}
      </List>
    </div>
  )
}

export default CourseList
