import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  List,
  ImgCont,
  CourseBlock,
  CourseTitle,
  ReadMore,
  ContentWrapper,
} from "../components/Course/CourseStyles.js"

export const BlogList = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(limit: 3) {
        edges {
          node {
            id
            datePublished(formatString: "MMMM Do YYYY")
            image {
              gatsbyImageData(width: 400)
              title
            }
            title
            slug
          }
        }
      }
    }
  `)

  // This one works statically, how to do dynamically?
  //console.log("image2", image2)

  return (
    <div>
      <List>
        {data.allContentfulBlog.edges.map(edge => {
          const image = getImage(edge.node.image?.gatsbyImageData)
          //console.log("BlogList Image", image)
          return (
            <CourseBlock key={edge.node.id}>
              <ImgCont>
                {edge.node.image && <GatsbyImage image={image} alt={""} />}
              </ImgCont>
              <ContentWrapper>
                <CourseTitle>{edge.node.title}</CourseTitle>

                <ReadMore to={`/blogs/${edge.node.slug}`}>READ MORE</ReadMore>
              </ContentWrapper>
            </CourseBlock>
          )
        })}
      </List>
    </div>
  )
}

export default BlogList
