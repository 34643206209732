import styled from "styled-components"
//import { colors } from "./../../styles/global"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export const List = styled.div`
  background-color: var(--main-color);
  padding: 15px;
  display: flex;
  margin: 0 auto;
  min-height: auto;
  width: 100%;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    background: var(--main-color);
    align-items: center;
    font-size: 1.3em;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    flex-direction: column;
    background: var(--main-color);
    align-items: center;
    font-size: 1.3em;
  }
`

export const CourseBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  background: #252525;
  min-width: 22%;
  max-width: 31%;
  min-height: 20vh;
  max-height: auto;
  justify-content: space-around;
  margin: 1rem 1rem;
  list-style-type: none;
  overflow: hidden;
  word-wrap: break-word;
  border-radius: 15px;
  p {
    font-size: 0.7rem;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    // box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    //   0 0 0 11px rgb(255, 255, 255, 0.39), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.62), 0 2px 2px rgba(0, 0, 0, 0.8),
      0 8px 8px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.42),
      0 16px 16px rgba(0, 0, 0, 0.42);
    transform: scale(1.02);
  }
  /*   div:hover {
    background: #161616;
    opacity: 1;
    transition: background-color 0.7s;
  } */
  @media (min-width: 200px) and (max-width: 768px) {
    margin: 20px auto;
    min-width: 95%;
    max-width: 95%;
    a {
      min-width: 25%;
    }
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    margin: 20px auto;
    min-width: 95%;
    max-width: 95%;
    a {
      min-width: 25%;
    }
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    max-width: 80%;
    margin: 3rem;
    min-height: auto;
    max-height: 45vh;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  padding: 4%;
  flex-direction: column;
  justify-content: space-around;
  background-color: #252525;
  word-wrap: break-word;
  min-width: 50%;
`

export const ImgCont = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: center;
  overflow: hidden;
  min-width: 50%;
  img {
    object-fit: cover;
  }
`

export const CourseImg = styled(GatsbyImage)`
  z-index: 1001;
`
export const CourseTitle = styled.h2`
  font-size: 1.3vw;
  //font-size: clamp(3rem, 2.333vw, 9.5rem);

  @media (min-width: 200px) and (max-width: 600px) {
    font-size: 5vw;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 5vw;
  }
  @media (min-width: 601px) and (max-width: 800px) {
    font-size: 4vw;
  }
  @media (min-width: 801px) and (max-width: 1200px) {
    font-size: 3.5vw;
  }
`

export const ReadMore = styled(Link)`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  vertical-align: center;
  text-decoration: none;
  text-align: center;
  max-width: 50%;
  padding: 2% 5%;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 400;
  color: #ffc467;
  background-color: var(--main-color);
  border: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  border-radius: 10px;
  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -3px);
  }
  @media (min-width: 200px) and (max-width: 600px) {
    max-width: 70%;
    font-size: 2.6vw;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 70%;
    font-size: 2.6vw;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 0.7rem;
  }
  @media (min-width: 901px) and (max-width: 1100px) {
    font-size: 0.7rem;
  }
  @media (min-width: 1101px) and (max-width: 3000px) {
    font-size: 0.7rem;
  }
`

export const CCategory = styled.p`
  color: lightgrey;
  margin-left: 5px;
  padding-right: 15px;
  flex-direction: flex-end;
  max-width: 80%;
  margin-right: 5%;
`

// Wordt dit gebruikt? Zie hierboven, course image
export const StyledImage = styled(GatsbyImage)`
  display: block !important;
  margin: 0 auto;
`
