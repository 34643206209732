import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const handleSubmit = event => {
  event.preventDefault()
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": "contact",
      // your spreading "contact" string, this seems superfluous? but it works like this.
      ..."contact",
    }),
  })
    // At the moment you get a default Netlify submitted thank you modal, how do you use navigate, did you import it properly?

    .then(() => navigate("/thank-you/"))
    .catch(error => alert(error))
}

const NetlifyForm = () => {
  return (
    <Container>
      <FormFlex
        name="contact"
        method="POST"
        data-netlify="true"
        netlify
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="hidden" name="form-name" value="contact" />

        <RowContainer>
          <label htmlFor="name"> </label>

          <RowLeft>
            <FuturaTitle>Your Name:</FuturaTitle>
          </RowLeft>
          <RowRight>
            <Input type="text" name="name" id="name" />
          </RowRight>
        </RowContainer>
        <RowContainer>
          <label htmlFor="email"> </label>

          <RowLeft>
            <FuturaTitle>Your Email:</FuturaTitle>
          </RowLeft>
          <RowLeft>
            <Input type="email" name="email" id="email" />
          </RowLeft>
        </RowContainer>
        <RowContainer>
          <label htmlFor="textarea"> </label>

          <RowLeft>
            <FuturaTitle>Message: </FuturaTitle>
          </RowLeft>

          <RowRight>
            <TextArea name="message" id="textarea"></TextArea>
          </RowRight>
        </RowContainer>
        <RowContainer>
          <ButtonFlex>
            <Button type="submit" onSubmit={handleSubmit}>
              Send
            </Button>
          </ButtonFlex>
        </RowContainer>
      </FormFlex>
    </Container>
  )
}

const Container = styled.section`
  font-family: var(--main-font);
  color: white;
  display: flex;
  justify-content: center;
  max-height: auto;
`

const FuturaTitle = styled.h2`
  font-family: var(--main-font);
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 0px;
  padding-bottom: 0px;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 0px;
    margin-bottom: 10px;
  }
`

const FormFlex = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.25rem;
  flex-direction: row;
  width: 60%;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`
const RowLeft = styled.div`
  display: flex;
  width: 45%;
  // background: green;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-end;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const RowRight = styled.div`
  display: flex;
  width: 45%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Input = styled.input`
  padding: 15px 15px;
  font-size: 1.3rem;
  background: var(--dark-card);
  color: var(--white);
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  width: 100%;
  height: 7vh;
`

const TextArea = styled.textarea`
  background: var(--dark-card);
  padding: 25px 15px 10px 15px;
  font-size: 1.4rem;
  color: var(--white);
  // border-radius: 15px;
  outline: none;
  border: none;
  width: 100%;
  border-bottom: 1px solid white;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

// const WhiteTitle = styled.h2`
//   padding: 5px;
//   color: white;
//   font-size: 3em;
// `

const Button = styled.button`
  border: 1px solid white;
  font-family: var(--main-font);
  background: var(--dark-card);
  padding: 15px;
  color: var(--white);
  font-size: 1.3em;
  font-weight: bolder;
  border-radius: 15px;
  outline: none;
  min-height: auto;
  max-height: 90px;
  text-transform: uppercase;
  width: 120px;
  cursor: pointer;
`

const ButtonFlex = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  margin: 15px 0px;
`

export default NetlifyForm
