import React from "react"
import styled from "styled-components"

const SubStackHolder = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  // background: var(--secondary-color);
`

const SubStack = () => {
  return (
    <SubStackHolder>
      <iframe
        src="https://nickungerer.substack.com/embed"
        width="1180"
        height="220"
        frameBorder="0"
        scrolling="yes"
        title="substack"
      />
    </SubStackHolder>
  )
}

export default SubStack
