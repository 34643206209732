import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const FullPage = ({ children }) => {
  return (
    <>
      <BodyContainer>{children}</BodyContainer>
    </>
  )
}

const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: 25px;
  margin-bottom: 20px;
  min-height: 50vh;
  width: 95%;
  background: var(--main-color);
  // darker is #141414 maybe pass as prop
  margin: 0 auto;
  a {
    color: white;
  }

  a:hover {
    color: #ffc467;
    transition: 0.33s;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

FullPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FullPage
