import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import SubStack from "../components/Substack"

const BigBox = ({ buttonText }) => {
  return (
    <Container>
      <BigBoxx>
        <LinearH1>Never stop Learning</LinearH1>
        <Head>Looking for ways to keep improving?</Head>
        <Head>Sign Up for our Newsletter for great curated resources</Head>
        <SubStack />
        <ButtonContainer>
          <DarkButton to="/deal" role="button">
            {buttonText || "Learn more"}
          </DarkButton>
        </ButtonContainer>
      </BigBoxx>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    font-size: 0.5rem;
  }
`

const BigBoxx = styled.div`
  padding: 15px;
  width: 30vw;
  height: auto;
  background: var(--footer-color);
  border-radius: 40px;
  @media only screen and (max-width: 768px) {
    width: 90%;
    // min-height: 450px;
  }
`

const Head = styled.h2`
  text-align: center;
  margin: 0 auto;
  padding: 10px 30px;
  font-family: var(--jost-font), sans-serif !important;
  text-transform: uppercase;
  color: white;
  //font-size: clamp(3rem, -1.875rem + 8.333vw, 9.5rem) !important;
  font-size: 1rem;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 15px;
  }
`
const ButtonContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  //border: 1px solid green;
`

const DarkButton = styled(Link)`
  // TODO Animate background position on hover, because otherwise transition won't work?
  // see: https://stackoverflow.com/questions/23441060/animating-linear-gradient-using-css
  margin: 20px;
  font-family: var(--main-font), sans-serif;
  display: inline-block;
  border: none;
  border-radius: 10px;
  padding: 1rem 2rem;
  text-decoration: none;
  // background: var(--button-standard); = pink
  background: linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 400ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  :hover,
  :focus {
    //background: var(--button-standard-hover);
    background: linear-gradient(45deg, #ff6600, #ffc549, #ff6600);

    outline: none;
  }

  :focus {
    outline-offset: -4px;
    //background: var(--button-standard-hover);
    background: linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  }

  :active {
    transform: scale(1.1);
    //background: var(--button-standard-hover);
    background: linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  }
`

// TODO, import and remove, this is Duplicate from elements.js

const LinearH1 = styled.h1`
  margin: 0 auto;
  margin-bottom: 1.45rem;
  background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-content: center;
  word-wrap: break-word;
  //text-shadow: 2px 2px 5px goldenrod;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 80%;
  font-size: 2.8rem;
  text-transform: uppercase;
  @media (min-width: 200px) {
    min-width: 95%;
    font-size: 2.8rem;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    min-width: 95%;
    font-size: 1.8rem;
  }
`

export default BigBox
