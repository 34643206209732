import React from "react"
import styled from "styled-components"

const FeaturedArticle = () => {
  return (
    <FlexDiv>
      <h2>Featured Blog</h2>
      <Container>
        <p>
          This week the featured blog is an external one:{" "}
          <a href="https://www.joshwcomeau.com/blog/how-to-learn-stuff-quickly/">
            How to Learn Stuff Quickly
          </a>
          , by Josh W. Comeau.
        </p>
      </Container>
    </FlexDiv>
  )
}

const FlexDiv = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 70%;
  h2 {
    padding-left: 50px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Container = styled.section`
  min-height: auto;
  background-color: var(--dark-card);
  color: var(--text-gray);
  border-radius: 30px;
  padding: 30px;
  margin: 20px;
  min-height: 50vh;

  a {
    // color: var(--secondary-color);
    color: darkorange;
    text-decoration: none;
  }
`

export default FeaturedArticle
