import React from "react"
import styled from "styled-components"

const CompoundHero = () => {
  return (
    <Container>
      <Background>Compound Yourself</Background>
      <Foreground>Compound Yourself</Foreground>
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 30vh;
  max-height: auto;
  /* @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    height: auto;
  } */
`

const Background = styled.h2`
  color: #252525;
  font-size: clamp(1rem, 7vw, 8vw);
  word-wrap: break-word;
  text-transform: uppercase;
  max-width: 100%;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Foreground = styled.h2`
  font-size: clamp(1rem, 4vw, 5rem);
  position: absolute;
  z-index: 101;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: 3rem;
  }
`

export default CompoundHero
