import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import "./layout.css"
import Header from "./Navigation/Header"
import Footer from "./Navigation/Footer"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Wrapper>
        <BodyContainer>
          <main>{children}</main>
        </BodyContainer>
      </Wrapper>
      <Footer />
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: center;
`

const BodyContainer = styled.div`
  min-height: 80vh;
  background: var(--main-color);
  width: 100%;
  font-family: var(--body-font);
  h1 {
    font-family: var(--jost-font);
  }

  h2 {
    font-family: var(--jost-font);
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
