import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
// import { /*GatsbyImage*/ getImage } from "gatsby-plugin-image"
import "normalize.css"
import Layout from "../components/layout.js"
import Seo from "../components/Seo.js"

import {
  FrameWrap,
  FrameText,
  LinearH1,
  HeaderGrid,
  Paragraph,
  // ImgWrap,
} from "./../styles/elements.js"

import NewAnim from "../components/NewAnim"
import FullPage from "../components/FullPage"
import CourseList from "../components/Course/CourseList"
import CookieConsent from "react-cookie-consent"
import BigBox from "../components/BigBox.js"
import NetlifyForm from "../components/NetlifyForm.js"
import SimpleText from "../components/SimpleText.js"
import BlogList from "../Blog/BlogList.js"
import Button from "../components/Button.js"
// TODO: Hook up component to Revue, MailChimp, Substack or w/e import NewsLetterBox from "../components/NewsLetterBox.js"
import CompoundHero from "../components/CompoundHero.js"
import SideBlogList from "../Blog/SideBlogComp.js"
import FeaturedArticle from "../components/FeaturedArticle.js"

const ContentGrid = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  margin: 0 auto;
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCourse(limit: 10) {
        edges {
          node {
            id
            author
            category
            courseImage {
              gatsbyImageData(layout: FULL_WIDTH)
              title
              fluid {
                src
              }
            }

            datePublished(formatString: "Do MMMM YYYY")
            subtitle
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="CompoundYourself: Guiding you toward the best online learning resources" />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://compoundyourself.com/" />
      </Helmet>

      <Layout>
        <HeaderGrid>
          <FrameWrap>
            <CompoundHero />
          </FrameWrap>
          <FrameWrap>
            <LinearH1>1% better each day.</LinearH1>
            <Paragraph>
              Want to get better at what you do? You've come to the right
              address.
            </Paragraph>
            <Button>
              <Link to="/categories">Discover Content</Link>
            </Button>
          </FrameWrap>
        </HeaderGrid>
        <Margin />
        <ContentGrid>
          <FeaturedArticle />
          <SideBlogList />
        </ContentGrid>
        <NewAnim>
          <LinearH1>Latest Blogs</LinearH1>
        </NewAnim>

        <BlogList />
        <Margin />

        <NewAnim>
          <LinearH1>Latest MasterClass Reviews</LinearH1>
        </NewAnim>
        <FrameText>
          <p>Reviewing our favorite classes on MasterClass.com</p>
        </FrameText>

        <Margin />
        <CourseList />
        {/* <TitleBox value="text">Can MasterClass teach you anything?</TitleBox> */}
        {/* <TitleBox textColor="rebeccapurple" value="">
          Can MasterClass teach you anything?
        </TitleBox> */}
        <FullPage>
          <div>
            <LinearH1>Or check out their other courses:</LinearH1>

            {data.allContentfulCourse.edges.map(edge => {
              return (
                <div key={edge.node.id}>
                  <a href={`/courses/${edge.node.slug}`} key={edge.node.id}>
                    <h1>{edge.node.title}</h1>
                  </a>
                </div>
              )
            })}
          </div>
        </FullPage>
        <BigBox buttonText="Learn more" />
        {/* <NewsLetterBox /> */}

        <SimpleText />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
        >
          This site uses cookies to improve user experience.
        </CookieConsent>
        <LinearH1>Contact Us</LinearH1>
        <NetlifyForm />
      </Layout>
    </>
  )
}

const Margin = styled.div`
  margin: 6rem 0rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

export default IndexPage

/* 

CUSTOMIZE REACT-COOKIE-CONSENT BANNER
If you want to customize the react cookie consent banner with your own css classes you can do it by setting disableStyles to true and then pass your css classes name to the buttonClasses, containerClasses, contentClasses props.

<CookieConsent
  disableStyles={true}
  location="bottom"
  buttonClasses="btn-custom-class"
  containerClasses="container-custom-class"
  contentClasses="content-custom-class"
>
  This website uses cookies to enhance the user experience.
</CookieConsent>

*/
