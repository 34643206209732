import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

const NewAnim = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  })
  return (
    <>
      <Anim
        ref={ref}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        animate={
          inView
            ? {
                opacity: 1,
                y: -20,
              }
            : { opacity: 0, y: 0 }
        }
      >
        {children}
      </Anim>
    </>
  )
}

const Anim = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: var(--main-font);
  font-size: 1rem;
  color: #000;
  margin-top: 40px;

  a {
    text-align: center;
  }
`

export default NewAnim
