import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Container = styled.section`
  position: relative;
  margin: 20px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const List = styled.div`
  height: auto;
  width: 20vw;
  padding-top: 50px;
  //position: absolute;
  @media only screen and (max-width: 768px) {
    font-size: 5rem;
  }
`
const ImgCont = styled.div`
  border-radius: 25%;
  overflow: hidden;
  display: flex;
  margin: 5%;
  justify-content: center;
  position: relative;
  display: flex;
  min-width: 20%;
  max-width: 23%;
  @media (min-width: 200px) and (max-width: 768px) {
    // flex-direction: column;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    //width: 100%;
  }
`
const CourseBlock = styled.div`
  width: 100%;
  margin: 20px 0px 20px 0px;
  display: flex;
  border-radius: 15px;
  background: var(--dark-card);
`

const ContentWrapper = styled.div`
  margin: 4% 2%;
  min-width: 40%;
  max-width: 40%;
  height: auto;
  color: var(--text-gray);
  align-self: center;
`

const CourseTitle = styled.div`
  color: white;
  font-family: var(--main-font);
  font-size: clamp(0.5em, 1.1vw, 1.3vw);
`

export const ReadMore = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  align-self: center;
  padding: 2% 3%;
  color: var(--text-gray);
  height: min-content;
  background-color: var(--secondary-color);
  border: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  border-radius: 10px;
  width: 20%;
  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -3px);
  }
  @media (min-width: 200px) and (max-width: 600px) {
    max-width: 70%;
    font-size: 2.6vw;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 70%;
    font-size: 2.6vw;
  }
  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 0.5rem;
  }
  @media (min-width: 901px) and (max-width: 1100px) {
    font-size: 0.5rem;
  }
  @media (min-width: 1101px) and (max-width: 3000px) {
    font-size: 0.8rem;
  }
`

export const SideBlogList = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(limit: 5) {
        edges {
          node {
            id
            datePublished(formatString: "MMMM Do YYYY")
            image {
              gatsbyImageData(width: 400)
              title
            }
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <Container>
      <List>
        <h2 style={{ paddingLeft: 20 }}>Latest blogs </h2>
        {data.allContentfulBlog.edges.map(edge => {
          const image = getImage(edge.node.image?.gatsbyImageData)
          //console.log("BlogList Image", image)
          return (
            <CourseBlock key={edge.node.id}>
              <ImgCont>
                {edge.node.image && <GatsbyImage image={image} alt={""} />}
              </ImgCont>
              <ContentWrapper>
                <CourseTitle>{edge.node.title}</CourseTitle>
              </ContentWrapper>
              <ReadMore to={`/blogs/${edge.node.slug}`}>READ</ReadMore>
            </CourseBlock>
          )
        })}
      </List>
    </Container>
  )
}

export default SideBlogList
