import React from "react"
import styled from "styled-components"

const SimpleText = () => {
  return (
    <Container>
      <Margin />
      <LinearH1>
        Compound Yourself is the place to learn to your heart's desire
      </LinearH1>
      <StyledP>
        Don't compare yourself to others. Only compare yourself to your past
        self.
      </StyledP>
      <StyledP>
        If you want to become better each day, start learning now, and attain 1%
        growth each day.
      </StyledP>
      <StyledP>
        That growth will compound into massive progress down the road.
      </StyledP>
      <Margin />
    </Container>
  )
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 100%;
  padding: 30px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    max-width: 40%;
  }
`

// Add to global, duplicates
const Margin = styled.div`
  margin: 6rem 0rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

// TODO, import and remove, this is Duplicate from elements.js

const LinearH1 = styled.h1`
  margin: 0 auto;
  margin-bottom: 1.45rem;
  background: -webkit-linear-gradient(45deg, #ffc549, #ff6600, #ffc549);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-content: center;
  word-wrap: break-word;
  //text-shadow: 2px 2px 5px goldenrod;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  max-width: 80%;
  font-size: 2.8rem;
  text-transform: uppercase;
  @media (min-width: 200px) {
    min-width: 95%;
    font-size: 2.8rem;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    min-width: 95%;
    font-size: 1.8rem;
  }
`

const StyledP = styled.p``

export default SimpleText
